import React from "react";
import { css } from "@emotion/react";
import { Box } from "reflexbox";

export function StyleReset({ children }) {
	return (
		<Box
			css={css`
				& * {
					padding: 0;
					margin: 0;
				}
			`}
		>
			{children}
		</Box>
	);
}
